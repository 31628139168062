<template>
	<div>
		<el-card>
			<div class="box-card">
				<img :src="hotelInfo.img" alt="" />
				<span class="mt-10 fz">{{ hotelInfo.jdmc }}</span>
				<span class="mt-10" style="color: #808080">{{
					hotelInfo.address
				}}</span>
				<el-divider class="divider"></el-divider>
				<div class="room">
					<span>{{ roomInfo.roomName }}</span>
					<!-- <img src="http://222.128.98.250:8303/static/images/hotel/yufu.png" alt="" /> -->
				</div>
				<el-divider class="divider"></el-divider>
				<div class="info-text">
					<span class="info-t-left">床型：</span>
					<span>{{ roomInfo.resRoomInfo.bedType ? roomInfo.resRoomInfo.bedType : '' }}</span>
				</div>
				<div class="info-text">
					<span class="info-t-left">可住人数：</span>
					<span>{{ roomInfo.resRoomInfo.broadnetAccess }}人</span>
				</div>
				<div class="info-text">
					<span class="info-t-left">楼层：</span>
					<span>{{ roomInfo.resRoomInfo.floor }}层</span>
				</div>
				<div class="info-text">
					<span class="info-t-left">面积：</span>
					<span>{{ roomInfo.resRoomInfo.area }} ㎡</span>
				</div>
				<div class="info-text">
					<span class="info-t-left">描述：</span>
					<span>{{ roomInfo.resRoomInfo.comments }}</span>
				</div>
				<div class="info-text">
					<span class="info-t-left">备注：</span>
					<span>{{ roomInfo.resRoomInfo.description }} </span>
				</div>
				<el-divider class="divider0"></el-divider>
				<el-popover placement="bottom" width="500" trigger="hover">
					<div class="hover-info" style="  height: 500px; overflow: auto  ;">
						<div class="td">
							<p>入住日</p>
							<p>早餐</p>
							<p>房价*间数</p>
						</div>
						<el-divider class="divider0"></el-divider>
						<div class="td" v-for="item in ninghtRomms" :key="item.index">
							<p> {{ item.date }}( {{ filtersTime(item.date) }})</p>
							<p>
								<!-- <span v-if="isFreeMeal > 2">多份早</span>
								<span v-if="isFreeMeal == 1 || isFreeMeal == 2">{{hItem.freeMeal}}份早餐</span>
								<span v-if="isFreeMeal == 0">无早餐</span> -->
								{{ isFreeMeal | breakfastTypeName }}
							</p>
							<p>￥{{ item.salePrice }}*{{ homeRooms }}<span v-if="isWbPromptBool && item.salePrice > jdjgbz"
									class="red">(超标)</span></p>
						</div>
						<el-divider class="divider0"></el-divider>

						<div class="tr">
							<p>服务费：</p>
							<p>¥{{ servicePrice }}</p>
						</div>

						<div class="tr">
							<p>总额：</p>
							<p>¥{{ price }}</p>
						</div>
					</div>
					<div class="info" slot="reference">
						<div class="check-in" style="width:350px">
							<div class="left">
								<p>
									<span>入住
										<p>{{ night }}晚</p>
									</span>
									<span v-if="gkfs == 3">企业支付
										<p>{{ compPayAmt }}</p>
									</span>
								</p>
								<p>
									<span>客房
										<p>{{ homeRooms }}间</p>
									</span>
									<span v-if="gkfs == 3">个人自付
										<p>{{ cbje }}</p>
									</span>
								</p>
							</div>
							<p class="right">¥{{ price }}</p>
						</div>
					</div>
				</el-popover>

			</div>
		</el-card>
		<el-card class="card2" style="margin-top: 27px">
			<div class="info">
				<span>预订须知</span>
				<span>
					预付订单，付款预订成功后，房间会一直保留。订单提交后需要在线支付房费，如未预订成功，房费全部原路退还。退还金额境内卡5个工作日内到账，境外卡20个工作日内到账
				</span>

			</div>
			<div class="info"
				v-if="(choosePricePlanItem.priceFromInfo == 1 || choosePricePlanItem.priceFromInfo == 2) && (choosePricePlanItem.corpGroup == 1 || choosePricePlanItem.bussType == 2)">
				<span>协议价提示</span>
				<span>
					协议价和正常价一样，可能会出行满房/价格变动，且不保证预定成功。
				</span>
			</div>
			<div class="info">
				<span>取消规则</span>
				<span>
					{{ description }}
				</span>
			</div>
			<div class="info" v-show="choosePricePlanItem.bookEquity">
				<span>嘉享权益</span>
				<span>
					{{ choosePricePlanItem.bookEquity }}
				</span>
			</div>
		</el-card>
	</div>
</template>
<script>
import {
	mapState,
	mapGetters,
	mapMutations
} from 'vuex'

export default {
	props: {},
	computed: {
		...mapState({
			night: (state) => state.htCn.night,
			price: (state) => state.htCn.price,
			homeRooms: (state) => state.htCn.homeRooms,
			roomInfo: (state) => state.htCn.roomInfo,
			hotelInfo: (state) => state.htCn.hotelInfo,
			servicePrice: (state) => state.htCn.servicePrice,
			ninghtRomms: (state) => state.htCn.ninghtRomms,
			isFreeMeal: (state) => state.htCn.isFreeMeal,
			description: (state) => state.htCn.description,
			travelPolicy: state => state.htCn.htCnPolicy,
			htCnQuery: state => state.htCn.htCnQuery, // 
		}),
		...mapGetters({
			getVipParamterByParNo: 'common/getVipParamterByParNo',
		}),
		// 判断是否有超标
		isWbPromptBool() {
			let ht20043 = this.getVipParamterByParNo(20043, 0),
				isWb = ht20043 == 1 && this.tripType == 1 && this.htCnQuery.weiType != 1,
				jdjgbz = this.jdjgbz;

			if (isWb) {
				for (let item of this.ninghtRomms) {
					if (jdjgbz && jdjgbz < item.salePrice) {
						return true;
					}
				}
			}
			return false;
		},
		jdjgbz() {
			let policy = this.travelPolicy.policy || {},
				jdjgbz = policy.jdjgbz ? policy.jdjgbz - 0 : '';
			return jdjgbz;
		},
		tripType() {
			/* 默认当前因公因私*/
			return this.htCnQuery.tripType
		},
		choosePricePlanItem() {
			return this.roomInfo.choosePricePlanItem || {};
		},
		gkfs() {
			let policy = this.travelPolicy.policy || {},
				gkfs = policy.gkfs;
			return gkfs;
		},
		// 超标个人自付-公司支付金额
		compPayAmt() {
			let jy = this.$common.numMulti(this.night, this.homeRooms),
				policy = this.travelPolicy.policy || {},
				jdjgbz = policy.jdjgbz || 0,
				total = this.$common.numMulti(jdjgbz, jy);
			total = this.$common.addition(total, this.servicePrice);
			if (this.gkfs == 3 && this.$common.addition([this.price, -total]) < 0) {
				return this.price;
			}
			return total;
		},
		// 超标个人自付-个人支付金额
		cbje() {
			var total = this.gkfs == 3 ? this.$common.addition([this.price, -this.compPayAmt]) : 0;
			if (total < 0) return 0;
			return total;
		},
		// resRoomInfo:() => this.roomInfo.resRoomInfo[0]
		filtersTime() {
			return function (value) {
				return this.$dateTime.getWeekZhou(value)
			}
		}
	},
	components: {},
	mounted() {
		console.log(this.roomInfo, 'roomInfo', 'hotelInfo', this.hotelInfo)
	},
	data() {
		return {}
	},
	methods: {},
	filters: {
		breakfastTypeName(val = 0) {
			let retStr = '';
			if (val == 1 || val == 2) {
				retStr = val + '份早';
			} else if (val > 2) {
				retStr = '多份早';
			} else {
				retStr = '不含早'
			}
			return retStr
		}
	}

}
</script>

<style scoped lang="scss">
/deep/ .el-popover {
	padding: 0;
}

.divider {
	margin: 20px 0px 15px 0;
}

.divider0 {
	margin: 0px;
}

.info-t-left {
	display: inline-block;
	text-align: left;
	width: 100px;
}

.fz {
	font-weight: bold;
	font-size: 20px;
}

.mt-10 {
	margin-top: 10px;
}

.red {
	color: red;
}

.hover-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.td {
		width: 100%;
		display: flex;
		justify-content: space-between;
		height: 50px;
		color: rgb(128, 128, 128);
		align-items: center;

		p {
			flex: 1;
		}
	}

	.tr {
		width: 80%;
		margin-top: 10px;
		display: flex;
		color: red;
		float: right;
		// color: rgb(128, 128, 128);

		justify-content: flex-end;
		// p:nth-child(2) {
		// 	color: #ff9524;
		// }
	}
}

.card2 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 15px;

		span:nth-child(1) {
			font-weight: bold;
			text-align: left;
		}

		span:nth-child(2) {
			margin-top: 10px;
			color: rgb(128, 128, 128);
			text-align: left;
		}
	}
}

.box-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	img {
		width: 100%;
		height: 150px;
	}

	.room {
		display: flex;
		align-items: center;
		font-size: 20px;

		img {
			margin-left: 20px;
			width: 40px;
			height: 25px;
		}
	}

	.info-text {
		margin-bottom: 15px;
	}

	.check-in {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-content: center;
		margin-top: 20px;

		.left {
			display: flex;

			span {
				display: flex;
				color: #808080;
				margin-right: 10px;

				p {
					color: #000;
				}
			}
		}

		.right {
			font-size: 20px;
			color: #ff9524;
		}
	}
}
</style>